import moment from 'moment';
import english from '../../../intl/en';
import spanish from '../../../intl/es';
import deutsch from '../../../intl/de';
import french from '../../../intl/fr';

import { IInstance, InstanceAction, Languages } from './types';

export interface ILocaleState {
  instance: IInstance;
  isFetching: boolean;
  error: any;
}

const setIntl = (lang = Languages.FR) => {
  switch (lang) {
    case Languages.ES:
      moment.locale('es');
      return spanish;
    case Languages.EN:
      moment.locale('en');
      return english;
    case Languages.DE:
      moment.locale('de');
      return deutsch;
    case Languages.FR:
      moment.locale('fr');
      return french;
    default:
      moment.locale('fr');
      return french;
  }
};

const initialState: ILocaleState = {
  instance: {
    name: '',
    background: '',
    logo: '',
    color: '',
    language: Languages.FR,
    messages: setIntl(),
  },
  isFetching: false,
  error: null,
};

export const locale = (state = initialState, action: InstanceAction) => {
  switch (action.type) {
    case 'INSTANCE/FETCH_REQUEST': {
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    }
    case 'INSTANCE/FETCH_FAILURE': {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case 'INSTANCE/FETCH_SUCCESS': {
      return {
        ...state,
        instance: {
          ...action.data[0],
          language: action.data[0].language,
          messages: setIntl(action.data[0].language),
        },
        isFetching: false,
      };
    }
    default:
      return state;
  }
};
