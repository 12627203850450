import * as React from 'react';
import {
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';

import { Header, Tab } from '../../../components';
import Dashboard from '../../Dashboard';
import Editor from '../../Editor';
import Creative from '../../Creative';

import Emergency from '../../Emergency';
import LoginPage from '../../Login';
import PasswordReset from '../../PasswordReset';
import { SectionCustom } from './styles';
import {
  verify,
  AppState,
  statusFilterList,
  IUser,
} from '../../../redux/modules';
import { UserConsumer } from '../../../providers';

const { Component, Fragment } = React;
const { REACT_APP_DEFAULT_ROUTE = 'dashboard' } = process.env;

interface IProps extends RouteComponentProps<{}> {
  verify?: any;
  user?: {
    isAdmin: boolean;
    loggingOut?: boolean;
    authenticated?: boolean;
    logout?: any;
  };
}

interface RoutesProps extends IProps {
  firstLoadFinished?: any;
}

class Routes extends Component<RoutesProps> {
  fetchFirstLoad() {
    this.props.verify();
  }

  async componentDidMount() {
    this.fetchFirstLoad();
  }

  render() {
    if (!this.props.user || !this.props.user.authenticated === undefined)
      return null;

    return (
      <Fragment>
        <SectionCustom>
          {!this.props.user.authenticated ? (
            <Switch>
              <Route path="/login" exact component={LoginPage} />
              <Route path="/password-reset" exact component={PasswordReset} />
              <Redirect to="/login" />
            </Switch>
          ) : (
            <Fragment>
              <Header />
              <Switch>
                <Route
                  path="/editor/:id/:emergency?"
                  exact
                  component={Editor}
                />
                <Route path="/dashboard" exact component={Dashboard} />
                <Route path="/creative" exact component={Creative} />
                <Route path="/emergency" exact component={Emergency} />
                <Redirect to={`/${REACT_APP_DEFAULT_ROUTE}`} />
              </Switch>
            </Fragment>
          )}
        </SectionCustom>
        <Tab />
      </Fragment>
    );
  }
}
// render() {
//   return (
//     <UserConsumer>
//       {user =>
//         !(user && this.props.firstLoadFinished) ? null : (
//           <Fragment>
//             <SectionCustom>
//               {!user.authenticated ? (
//                 <Switch>
//                   <Route path="/login" exact component={LoginPage} />
//                   <Route
//                     path="/password-reset"
//                     exact
//                     component={PasswordReset}
//                   />
//                   <Redirect to="/login" />
//                 </Switch>
//               ) : (
//                 <Fragment>
//                   <Header />
//                   <Switch>
//                     <Route
//                       path="/editor/:id/:emergency?"
//                       exact
//                       component={Editor}
//                     />
//                     <Route path="/dashboard" exact component={Dashboard} />
//                     <Route path="/creative" exact component={Creative} />
//                     <Route path="/emergency" exact component={Emergency} />
//                     <Redirect to="/dashboard" />
//                   </Switch>
//                 </Fragment>
//               )}
//             </SectionCustom>
//             <Tab />
//           </Fragment>
//         )
//       }
//     </UserConsumer>
//   );
// }

class WrapperRoute extends React.Component<IProps> {
  render() {
    return (
      <Fragment>
        <UserConsumer>
          {user => <Routes {...this.props} user={user} />}
        </UserConsumer>
      </Fragment>
    );
  }
}
export default withRouter(
  connect(
    (state: AppState) => ({ firstLoadFinished: state.user.isFinished }),
    { verify }
  )(WrapperRoute)
);
