export default {
  error: 'Error',
  success: ' Succès',
  'editor.empty': 'Champ vide',
  'editor.loader': 'Chargement...',
  'editor.info.title': 'Edition de contenu',
  'editor.info.emergency.title': "Édition de d'alerte",
  'editor.info.field.creativity': 'Nommez votre création',
  'editor.info.field.slide': 'Nom de la diapositive',
  'editor.info.placeholer.creativity': 'Entrez le nom de votre création',
  'editor.info.placeholer.slide': 'Insérer le nom de la diapositive',
  'editor.info.placeholer.element.text': 'Entrez votre texte',
  'editor.info.placeholer.element.image': "Inserez l'url de votre image",
  'editor.info.locations.title': 'Emplacements',
  'editor.info.locations.playersScreenTitle': '{number} ecrans sélectionnés',
  'editor.info.locations.groupsScreenTitle': '{number} groupe sélectionnés',
  'editor.info.locations.seeTitle': 'Voir les emplacements',
  'editor.info.locations.allLocationsTitle': 'Tous les écrans',
  'editor.info.locations.specificLocationsTitle': 'Écrans spécifiques',
  'editor.info.screen.buttonSelectScreen': 'Choisir par écrans',
  'editor.info.screen.titleActivity': 'Programmation de la diffusion',
  'editor.info.elements.sectionTitle': 'Contenu',

  'editor.error.uploadImage.InvalidFileDimensions':
    "Les dimensions de l'image ne correspondent pas à l'original: {width} x {height}",
  'editor.error.uploadImage.InvalidFileExtension':
    'L’extension du fichier image doit être jpg, jpeg, png ou svg',
  'editor.error.uploadImage.default': 'Image upload failed',

  'editor.error.uploadVideo.InvalidFileDimensions':
    "les dimensions de la vidéo ne correspondent pas à l'original: {width} x {height}",
  'editor.error.uploadVideo.InvalidFileExtension':
    "L'extension du fichier vidéo doit être mp4, mov, avi, m4a, 3gp",
  'editor.error.uploadVideo.default': 'Video upload failed',

  'screenSelection.title': "Sélection d'écran",
  'screenSelection.phraseSubtitle':
    "Sélectionnez un groupes d'écran pour diffuser sur tous les écrans qui le compose",
  'screenSelection.tabs.option1': "Sélectionner par groupe d'écran",
  'screenSelection.tabs.option2': 'Sélectionner écran par écran',
  'dashboard.bygroup': 'Visualisez les contenu diffuser sur:',

  'modal.footer.buttonOk': 'Terminé',
  'modal.footer.buttonSubmit': 'Soumettre',
  'modal.footer.buttonClock': 'Ok',
  'modal.body.title': 'Votre session a expiré',
  'modal.body.text':
    'Votre session a été fermée parce que nous avons détecté une inactivité. Pour continuer, veuillez vous connecter à nouveau.',

  'screenSelection.panels.button.selectAll': 'Tout sélectionner',
  'screenSelection.panels.button.addSelect': 'Ajouter à la sélection',
  'screenSelection.panels.button.removeSelection': 'Supprimer de la sélection',
  'screenSelection.panels.info.noItemsSelects': `Vous n'avez pas sélectionné d'écrans`,
  'cardInfo.detail.template': 'Modèle:',
  'cardInfo.detail.author': 'Auteur:',
  'cardInfo.detail.created': 'Date de creation:',
  'cardInfo.detail.start': 'Début:',
  'cardInfo.detail.end': 'Fin:',
  'cardInfo.detail.totalPlaybacks': 'Total playbacks:',
  'cardInfo.detail.playbacks': '{playbacks} playbacks',

  'creativeList.byStatus': 'Montrer',
  'creativeList.nocreatives': 'Aucun résultat',
  'creativeList.selectTemplate':
    'Choississez le type de contenu que vous voulez créer',
  'creativeList.placeholder': 'Nouveau à partir du modèle',
  'creativeList.create': 'Valider',
  'creativeList.createCreative': 'Créer un nouveau contenu',
  'creativeList.filterCreative': 'Filtre',
  'creativeList.tag.showing': 'Montrant',
  'creativeList.tag.all': 'tous',
  'creativeList.tag.drafts': 'brouillons',
  'creativeList.tag.published': 'publiées',
  'creativeList.tag.completed': 'diffusées',
  'creativeList.sort': 'Trier par',
  'creativeList.sort.date': 'Date de création',
  'creativeList.sort.name': 'Nom',
  'creativeList.sort.creation': 'Date de création',
  'creativeList.sort.start': 'Date de début',
  'creativeList.sort.end': 'Date de fin',

  'route.default': 'Portail ville',
  'route.dashboard': 'Mes Diffusions',
  'route.creative': 'Bibliothèque',
  'route.emergency': 'Mode Alerte',
  'route.login': 'Connexion',
  'component.upload': 'ou',
  'component.uploadButton': 'Télécharger',
  'condition.clear': 'Ensoleillé',
  'condition.partly cloudy': 'Partiellement nuageux',
  'condition.cloudy': 'Nuageux',
  'condition.rain': 'Pluie',
  'condition.drizzle': 'Rares averses',
  'condition.hail': 'Averses pluie/neige',
  'condition.thunderstorm': 'Pluie orageuse',
  'condition.snow': 'Neige',
  'condition.fog': 'Brouillard',
  'condition.haze': 'Léger voile',
  'condition.sand': 'Tempete de sable',
  'condition.dust': 'Voile dense',
  'condition.smoke': 'Fumée',
  'condition.ash': 'Cendres volcaniques',
  'condition.squalls': 'Vents violents',
  'condition.unknown': 'Inconnu',
  'condition.undefined': 'Inconnu',
  'condition.chance': 'Chance',
  'user.username': 'Connexion',
  'user.password': 'Mot de passe',
  'user.errorlogin': 'Erreur de connexion',
  'app.logout': 'Déconnexion',
  'emergency.deactivate': 'Désactivé',
  'emergency.alertActive': 'Mode alerte activé',
  'app.all': 'Tous les statuts',
  'app.reset': 'Réinitialiser',
  'dashboard.next': 'Prochaines diffusions programmées',
  'dashboard.current': 'En diffusion',
  'dashboard.allGroups': 'Tous vos écrans',
  'emergency.publishAlert': "Publier l'alerte",
  'emergency.terrorism': 'Menace Terroriste',
  'emergency.natural': 'Risques Naturels',
  'emergency.sanitary': 'Risque Sanitaire',
  'emergency.technologic': 'Risque Technologiques',
  'emergency.noEmergencies': 'Aucune alerte',

  'actions.modalPublishTitle': 'Publier la création',
  'actions.modalDeleteTitle': 'Supprimer la création?',
  'actions.modalDeleteText':
    'Une fois que vous avez supprimé la création, vous ne pouvez pas la récupérer',
  'actions.modalConfirm': 'Confirmer',
  'actions.deleteModalConfirm': 'Supprimer',
  'actions.modalCancel': 'Annuler',
  'actions.creative.modalText': 'Attention! Voulez-vous le publier?',
  'actions.emergency.modalText':
    'Attention! Publier une alerte stoppera toutes vos autres diffusions. Voulez-vous publier l’alerte?',
  'actions.buttons.deactivate': 'Désactiver',
  'actions.buttons.save': 'Sauvegarder',
  'actions.buttons.publish': 'Publier',
  'actions.buttons.validate&publish': 'Valider & Publier',
  'actions.buttons.requestValidation': 'Demande validation',
  'actions.buttons.delete': 'Supprimer',
  'actions.buttons.unpublish': 'Annuler la publication',
  'actions.buttons.play': 'Lire le gabarit',
  'actions.buttons.reset': 'Réinitialiser',
  'actions.button.send': 'Envoyer',
  'actions.buttons.popOver.isDefault':
    'La création ne peut pas être supprimée car elle est définie par défaut.',
  'actions.buttons.denyComment': 'Refuser & commenter',
  'actions.buttons.approvePublish': 'Approuver & publier',
  'editor.visibility.dateRange': 'Sélectionnez une période',
  'editor.visibility.to': ' à ',
  'select.defaultValue.template': 'Nouveau à partir du modèle',
  'dashboard.phraseWelcome': 'Bonjour',
  'emergency.emergencyGroup.noElements': 'Aucune alerte',
  'emergency.titleGroups.terrorirsm': 'Menace Terroriste',
  'emergency.titleGroups.natural': 'Risques Naturels',
  'emergency.titleGroups.technologic': 'Risque Technologiques',
  'emergency.titleGroups.sanitary': 'Risque Sanitaire',
  'passwordReset.back': 'Connexion',
  'passwordReset.recover': 'Mot de passe oublié',
  'passwordReset.sendAgain': 'Envoyer à nouveau',
  'passwordReset.error.invalidEmail': 'Email incorrect',

  'editor.error.notEmpty.description': 'Le champ ne doit pas être vide',

  'All groups': 'Tous les écrans',
  All: 'Tous les statuts',
  Published: 'Publié',
  Draft: 'Brouillon',
  Completed: 'Diffusé',
  Name: 'Nom',
  Creation: 'Date de création',
  Start: 'Date de début',
  End: 'Date de fin',
  'Missing required parameter: username': "Nom d'utilisateur requis",
  'Missing required parameter: password': 'Mot de passe requis',
  'Wrong email or password.': 'Email ou mot de passe incorrect.',

  "We've just sent you an email to reset your password.":
    'Nous venons de vous envoyer un email pour réinitialiser votre mot de passe.',

  'editor.uploadImage.success': 'Image téléchargée avec succès',
  'editor.uploadVideo.success': 'Video téléchargée avec succès',

  ddmmyy: 'JJ/MM/AA',

  'weekdayselector.all': 'Toute la semaine',
  'weekdayselector.specificDays': 'Jours spécifiques',
  'weekdayselector.monday': 'L',
  'weekdayselector.tuesday': 'M',
  'weekdayselector.wednesday': 'M',
  'weekdayselector.thursday': 'J',
  'weekdayselector.friday': 'V',
  'weekdayselector.saturday': 'S',
  'weekdayselector.sunday': 'D',

  'HourSelector.allHours': 'Toutes les heures',
  'HourSelector.specificHours': 'Heures spécifiques',

  'loading.message': "Traitement vidéo, cela peut prendre jusqu'à 2 minutes",

  'creativeList.deletePopOver.title':
    'La création ne peut pas être supprimée si:',
  'creativeList.deletePopOver.text1': `- C'est une création définie par défaut`,
  'creativeList.deletePopOver.text2': `- C'est une création programmée ou publiée`,
  'creativeList.deletePopOver.text3':
    "- C'est une création en attente de validation",
  'creativeList.defaultCreative.message': 'Création par défaut choisie',
  'creativeList.deleteCreative.message': 'Suppression de création',
  'creativeList.edit.tooltip.title': 'Modifier la création',
  'creativeList.alreadyDefault.popOver':
    'Cette création est déjà définie par défaut.',
  'creativeList.notAdmin.popOver': `Vous n'êtes pas autorisé à définir une création par défaut.`,
  'creativeList.setDefault.tooltip.title': 'Définir par défaut',
  'creativeList.delete.tooltip.title': 'Supprimer la création',

  'tag.draft': 'Brouillon',
  'tag.pending': 'En attente de validation',
  'tag.created': 'Créé',
  'tag.completed': 'Diffusé',
  'tag.ongoing': 'En Cours',
  'tag.scheduled': 'Prévue',

  'loginPage.footer.rgpd1':
    'Clear Channel France s’engage à protéger vos Données Personnelles. Vous pouvez consulter la mise à jour de notre politique d confidentialité et de cookies ',
  'loginPage.footer.rgpd.link': 'ici',
  'loginPage.footer.rgpd2':
    ' et mettre à jour vos préférences, et ce à tout moment. Si vous avez des questions concernant notre politique de confidentialité, contactez: ',
  'loginPage.footer.rgpd.mail': 'mydata@clearchannel.com',
};
