import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';
import { Popover } from 'antd';
import {
  AppState,
  hasLocationSelector,
  hasVisibilitySelector,
  getPublishedEmergency,
  updateCreativity,
  deleteCreativity,
  changeCreativeStatus,
  Status,
} from '../../../../redux/modules';
import { Button, Modal } from '../../../../components';
import { getPlayerUrl } from '../../../../utils';
import { UserConsumer } from '../../../../providers';

import {
  Container,
  WrapperContainer,
  WrapperButton,
  WrapperText,
  FlexContainer,
  EmergencyButtonFlexContainer,
  ButtonWrapper,
  UnpublishButtonWrapper,
  DisableDeleteButton,
  DenyCommentButton,
  ButtonsContainer,
} from './styles';

import moment from 'moment';

interface IProps {
  id: string;
  error?: any;
  isPublishable?: boolean;
  hasPublishedEmergency?: boolean;
  auth?: any;
  isEmergency?: boolean;
  updateCreativity?: any;
  unpublishCreativity?: any;
  intl?: InjectedIntl;
  deleteCreativity?: any;
  status?: Status;
  changeCreativeStatus?: any;
  selected?: boolean | undefined;
  endDate?: string;
}

interface IState {
  visiblePublishModal: boolean;
  visibleDeleteModal: boolean;
}

interface IProfile {
  groups: string[];
}

class Actions extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      visiblePublishModal: false,
      visibleDeleteModal: false,
    };
    this.handleDonePublishModal = this.handleDonePublishModal.bind(this);
    this.handleCancelPublishModal = this.handleCancelPublishModal.bind(this);
    this.openModalPublish = this.openModalPublish.bind(this);
    this.openModalDelete = this.openModalDelete.bind(this);
    this.handleDoneDeleteModal = this.handleDoneDeleteModal.bind(this);
    this.handleCancelDeleteModal = this.handleCancelDeleteModal.bind(this);
  }

  dateBeforeValidation = () => {
    const date = moment(this.props.endDate, 'D/M/YYYY');
    const today = moment().startOf('day');
    return today.isSameOrBefore(date);
  };

  handleSaveCreativity = () => this.props.updateCreativity(this.props.id, 2);

  handlePublishCreativity = () => {
    this.props.updateCreativity(this.props.id, 4);
  };

  handlePlay = () => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.open(getPlayerUrl(this.props.id));
  };

  handleDeleteCreativity = () => this.props.deleteCreativity(this.props.id);

  getChangeStatusHandler = (status: Status) => () => {
    return this.props.changeCreativeStatus(this.props.id, status);
  };
  openModalDelete() {
    this.setState({ visibleDeleteModal: true });
  }

  closeModalDelete() {
    this.setState({ visibleDeleteModal: false });
  }

  openModalPublish() {
    this.setState({ visiblePublishModal: true });
  }

  closeModalPublish() {
    this.setState({ visiblePublishModal: false });
  }

  handleDonePublishModal() {
    this.handlePublishCreativity();
    this.closeModalPublish();
  }

  handleCancelPublishModal() {
    this.closeModalPublish();
  }

  handleDoneDeleteModal() {
    this.handleDeleteCreativity();
    this.closeModalDelete();
  }

  handleCancelDeleteModal() {
    this.closeModalDelete();
  }

  renderEmergencyActions = () => {
    const { status, intl } = this.props;
    return (
      <Fragment>
        <UserConsumer>
          {user => (
            <EmergencyButtonFlexContainer>
              {user.isAdmin &&
                (status === Status.PUBLISHED ? (
                  <Button
                    type="red"
                    onClick={this.getChangeStatusHandler(2)}
                    button={intl!.formatMessage({
                      id: 'actions.buttons.deactivate',
                    })}
                    padding={20}
                  />
                ) : (
                  <Button
                    onClick={this.openModalPublish}
                    button={intl!.formatMessage({
                      id: 'actions.buttons.publish',
                    })}
                    padding={20}
                  />
                ))}
            </EmergencyButtonFlexContainer>
          )}
        </UserConsumer>
      </Fragment>
    );
  };

  renderSelected(selected: any) {
    const { intl } = this.props;
    {
      return selected ? (
        <Popover
          content={intl!.formatMessage({
            id: 'actions.buttons.popOver.isDefault',
          })}
          trigger="hover"
          placement="top"
        >
          <div>
            <DisableDeleteButton
              button={intl!.formatMessage({
                id: 'actions.buttons.delete',
              })}
              padding={8}
              type="deleteButton"
              className="disabled"
            />
          </div>
        </Popover>
      ) : (
        <Button
          onClick={this.openModalDelete}
          button={intl!.formatMessage({
            id: 'actions.buttons.delete',
          })}
          padding={8}
          type="deleteButton"
        />
      );
    }
  }

  renderButtonsStatusCreatedAndDraftIsAdmin(selected: any) {
    const { intl, isPublishable } = this.props;
    return (
      <FlexContainer>
        {this.renderSelected(selected)}
        <ButtonsContainer>
          <Button
            onClick={this.handleSaveCreativity}
            type="saveButton"
            button={intl!.formatMessage({
              id: 'actions.buttons.save',
            })}
            padding={8}
            icon="save"
          />
          <Button
            onClick={this.openModalPublish}
            button={intl!.formatMessage({
              id: 'actions.buttons.publish',
            })}
            disabled={!isPublishable || !this.dateBeforeValidation()}
            padding={8}
            type="publishButton"
          />
        </ButtonsContainer>
      </FlexContainer>
    );
  }

  renderButtonsStatusPendingValidationIsAdmin() {
    const { intl } = this.props;
    return (
      <FlexContainer>
        <DenyCommentButton
          onClick={this.getChangeStatusHandler(2)}
          button={intl!.formatMessage({
            id: 'actions.buttons.denyComment',
          })}
          padding={20}
        />
        <Button
          onClick={this.getChangeStatusHandler(4)}
          button={intl!.formatMessage({
            id: 'actions.buttons.approvePublish',
          })}
          padding={20}
        />
      </FlexContainer>
    );
  }

  renderButtonsStatusPublishIsAdmin() {
    const { intl } = this.props;
    return (
      <>
        <UnpublishButtonWrapper>
          <Button
            onClick={this.getChangeStatusHandler(2)}
            button={intl!.formatMessage({
              id: 'actions.buttons.unpublish',
            })}
            padding={20}
          />
        </UnpublishButtonWrapper>
      </>
    );
  }

  renderButtonsStatusCreatedAndDraftNoAdmin(selected: any) {
    const { intl, isPublishable } = this.props;
    return (
      <FlexContainer>
        {this.renderSelected(selected)}
        <Button
          onClick={this.handleSaveCreativity}
          type="whiteBlue"
          button={intl!.formatMessage({
            id: 'actions.buttons.save',
          })}
          padding={20}
        />
        <Button
          onClick={this.getChangeStatusHandler(3)}
          button={intl!.formatMessage({
            id: 'actions.buttons.requestValidation',
          })}
          padding={20}
          disabled={!isPublishable}
        />
      </FlexContainer>
    );
  }

  renderButtonsStatusPublishAndPendingValidationNoAdmin() {
    const { intl } = this.props;
    return (
      <FlexContainer>
        <Button
          onClick={this.openModalDelete}
          button={intl!.formatMessage({
            id: 'actions.buttons.delete',
          })}
          padding={20}
          type="delete"
          disabled
        />
        <Button
          onClick={this.handleSaveCreativity}
          type="whiteBlue"
          button={intl!.formatMessage({
            id: 'actions.buttons.save',
          })}
          padding={20}
          disabled
        />
        <Button
          onClick={this.getChangeStatusHandler(3)}
          button={intl!.formatMessage({
            id: 'actions.buttons.requestValidation',
          })}
          padding={20}
          disabled
        />
      </FlexContainer>
    );
  }

  renderCreativeActions = () => {
    const { status, selected } = this.props;
    return (
      <Fragment>
        <UserConsumer>
          {user => {
            if (user.isAdmin) {
              if (status === Status.PENDING_VALIDATION) {
                return this.renderButtonsStatusPendingValidationIsAdmin();
              } else if (status === Status.PUBLISHED) {
                return this.renderButtonsStatusPublishIsAdmin();
              } else {
                return this.renderButtonsStatusCreatedAndDraftIsAdmin(selected);
              }
            } else {
              if (
                status === Status.PENDING_VALIDATION ||
                status === Status.PUBLISHED
              ) {
                return this.renderButtonsStatusPublishAndPendingValidationNoAdmin();
              } else {
                return this.renderButtonsStatusCreatedAndDraftNoAdmin(selected);
              }
            }
          }}
        </UserConsumer>
      </Fragment>
    );
  };

  render() {
    const { isEmergency, intl } = this.props;
    const { visiblePublishModal, visibleDeleteModal } = this.state;
    return (
      <Fragment>
        <Modal
          title={intl!.formatMessage({
            id: 'actions.modalPublishTitle',
          })}
          handleCustomOk={this.handleDonePublishModal}
          handleCustomCancel={this.handleCancelPublishModal}
          visible={visiblePublishModal}
          noFooter={true}
        >
          <WrapperContainer>
            <WrapperText>
              {intl!.formatMessage({
                id: this.props.isEmergency
                  ? 'actions.emergency.modalText'
                  : 'actions.creative.modalText',
              })}
            </WrapperText>
            <WrapperButton>
              <Button
                type="whiteBlue"
                button={intl!.formatMessage({
                  id: 'actions.modalCancel',
                })}
                onClick={this.handleCancelPublishModal}
              />
              <Button
                button={intl!.formatMessage({
                  id: 'actions.modalConfirm',
                })}
                onClick={this.handleDonePublishModal}
              />
            </WrapperButton>
          </WrapperContainer>
        </Modal>

        <Modal
          title={intl!.formatMessage({
            id: 'actions.modalDeleteTitle',
          })}
          handleCustomOk={this.handleDoneDeleteModal}
          handleCustomCancel={this.handleCancelDeleteModal}
          visible={visibleDeleteModal}
          noFooter={true}
        >
          <WrapperContainer>
            <WrapperText>
              <FormattedMessage id="actions.modalDeleteText" />
            </WrapperText>
            <WrapperButton>
              <Button
                type="whiteBlue"
                button={intl!.formatMessage({
                  id: 'actions.modalCancel',
                })}
                onClick={this.handleCancelDeleteModal}
              />
              <Button
                button={intl!.formatMessage({
                  id: 'actions.modalConfirm',
                })}
                onClick={this.handleDoneDeleteModal}
              />
            </WrapperButton>
          </WrapperContainer>
        </Modal>

        <Container>
          {isEmergency
            ? this.renderEmergencyActions()
            : this.renderCreativeActions()}
        </Container>
      </Fragment>
    );
  }
}

export default connect(
  (state: AppState, props: IProps) => ({
    isPublishable:
      hasVisibilitySelector(state, props.id) &&
      hasLocationSelector(state, props.id),
    hasPublishedEmergency: !!getPublishedEmergency(state),
  }),
  {
    updateCreativity,
    deleteCreativity,
    changeCreativeStatus,
  }
)(injectIntl<any>(Actions));
