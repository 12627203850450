import React, { PureComponent } from 'react';
import {
  BlueButton,
  GreyButton,
  RedButton,
  WhiteBlueButton,
  WhiteRedButton,
  EmergencySelect,
  ResetButton,
  ResetBlueWhiteButton,
  Basicbutton,
  Preview,
  DeleteButton,
  SaveButton,
  PublishButton,
} from './styles';

interface IProps {
  type?: string;
  htmlType?: string;
  button?: string;
  onClick?: React.MouseEventHandler;
  padding?: number;
  disabled?: boolean;
  className?: string;
  icon?: string;
}

class Button extends PureComponent<IProps> {
  render() {
    const { type, disabled, padding, htmlType, className, icon } = this.props;
    if (type === 'delete') {
      return (
        <Basicbutton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType as 'button' | 'submit' | 'reset' | undefined}
          className={className}
        >
          {this.props.button}
        </Basicbutton>
      );
    }
    if (type === 'warning') {
      return (
        <WhiteRedButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType as 'button' | 'submit' | 'reset' | undefined}
          className={className}
        >
          {this.props.button}
        </WhiteRedButton>
      );
    }
    if (type === 'grey') {
      return (
        <GreyButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType as 'button' | 'submit' | 'reset' | undefined}
          className={className}
        >
          {this.props.button}
        </GreyButton>
      );
    }
    if (type === 'red') {
      return (
        <RedButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType as 'button' | 'submit' | 'reset' | undefined}
          className={className}
        >
          {this.props.button}
        </RedButton>
      );
    }
    if (type === 'whiteBlue') {
      return (
        <WhiteBlueButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType as 'button' | 'submit' | 'reset' | undefined}
          className={className}
        >
          {this.props.button}
        </WhiteBlueButton>
      );
    }
    if (type === 'whiteRed') {
      return (
        <WhiteRedButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType as 'button' | 'submit' | 'reset' | undefined}
          className={className}
        >
          {this.props.button}
        </WhiteRedButton>
      );
    }
    if (type === 'emergencySelect') {
      return (
        <EmergencySelect
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType as 'button' | 'submit' | 'reset' | undefined}
          className={className}
        >
          {this.props.button}
        </EmergencySelect>
      );
    }
    if (type === 'reset') {
      return (
        <ResetButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType as 'button' | 'submit' | 'reset' | undefined}
          className={className}
        >
          {this.props.button}
        </ResetButton>
      );
    }
    if (type === 'resetBlueWhite') {
      return (
        <ResetBlueWhiteButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType as 'button' | 'submit' | 'reset' | undefined}
          className={className}
        >
          {this.props.button}
        </ResetBlueWhiteButton>
      );
    }
    if (type === 'preview') {
      return (
        <Preview
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType as 'button' | 'submit' | 'reset' | undefined}
          className={className}
          icon={icon}
        >
          {this.props.button}
        </Preview>
      );
    }
    if (type === 'deleteButton') {
      return (
        <DeleteButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType as 'button' | 'submit' | 'reset' | undefined}
          className={className}
        >
          {this.props.button}
        </DeleteButton>
      );
    }
    if (type === 'saveButton') {
      return (
        <SaveButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType as 'button' | 'submit' | 'reset' | undefined}
          className={className}
          icon={icon}
        >
          {this.props.button}
        </SaveButton>
      );
    }
    if (type === 'publishButton') {
      return (
        <PublishButton
          disabled={disabled}
          padding={padding}
          onClick={this.props.onClick}
          htmlType={htmlType as 'button' | 'submit' | 'reset' | undefined}
          className={className}
        >
          {this.props.button}
        </PublishButton>
      );
    }

    return (
      <BlueButton
        disabled={disabled}
        padding={padding}
        onClick={this.props.onClick}
        htmlType={htmlType as 'button' | 'submit' | 'reset' | undefined}
        className={className}
      >
        {this.props.button}
      </BlueButton>
    );
  }
}
export default Button;
